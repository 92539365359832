// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

import 'flex.box/src/flexbox.css'

import customers from './customers.vue'

import router from './router'
import store from './store/store'

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, { locale })

import VueMdijs from 'vue-mdijs'
import {
  mdiTrashCanOutline,
  mdiRestore,
  mdiPlus,
  mdiClose,
  mdiCreditCardOutline,
  mdiCreditCardCheckOutline,
  mdiDotsVertical
} from '@mdi/js';
//
VueMdijs.add({
  mdiTrashCanOutline,
  mdiRestore,
  mdiPlus,
  mdiClose,
  mdiCreditCardOutline,
  mdiCreditCardCheckOutline,
  mdiDotsVertical
})
Vue.use(VueMdijs)

import icon from '@components/c-icon/index.vue'
Vue.component('c-icon', icon)

import Vuex from 'vuex'
Vue.use(Vuex)

import fetch from './fetch.js'
Vue.use(fetch)

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import messages from '../bookingForm/i8n/messages'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const i18n = new VueI18n({
    fallbackLocale: 'en',
    messages
})

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
Vue.use(VueMoment, {
    moment,
})

Vue.config.productionTip = false
axios.defaults.baseURL = `/api`

Vue.prototype.$guardModel = "customer",


/* eslint-disable no-new */
new Vue({
  el: '#app',
    i18n,
  store,
  router,
  components: { customers }
})

