<template>
    <main>
        <router-view ></router-view>
    </main>
</template>

<script>
export default {
    name: "customers",
    props:{
        company: {
            required: true
        },
        'stripe-key': {
            required: true
        }
    },
    mounted() {
        this.$store.dispatch('setCompany', this.company)
        this.$store.dispatch("refresh_customer_data");
        this.$store.dispatch('setStripeKey', this.stripeKey)
    }
};
</script>

<style lang="scss">

body,
html,
#app {
    font-family: 'Inter', sans-serif;
    height: 100%;
    position: unset;
}

body {
    margin: 0;
    background-color: #F3F3F3;
    overflow: auto;
    height: auto;
}

main {
    height: 100%;
    display: flex;
}

</style>
