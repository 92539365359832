import FetchInterceptor  from 'fetch-interceptor'
import store from './store/store'
const apiUrl = "/api/customers/";

  export default {



    install (Vue, baseUrl) {

    // Register interceptor hooks
        Vue.prototype.interceptor = FetchInterceptor.register({
            onBeforeRequest(request, controller) {
                // console.log(request,controller)
                request.headers.set('Accept', 'application/json')
                request.headers.set('Content-Type' , 'application/json')
                request.headers.set('Access-Control-Allow-Origin' , '*')


            },
            onRequestSuccess(response, request, controller) {
                // console.log('success', response)

            },
            onRequestFailure(response, request, controller) {
                //  console.log('fail',response)
                if(response.status === 401){
                    // console.log('Unauthorized, redirect to login')
                    store.commit('SET_LOGIN', false)
                }

            }
        }),

        Vue.prototype.$get = function(url){
            // console.log(apiUrl)
            return fetch(apiUrl+url,{
                method: 'GET',
                //mode: 'cors',
                //cache: 'no-cache',
                //credentials: 'same-origin',
                credentials: 'include',

            })
        }


        Vue.prototype.$post = function(url, data){
            // console.log(apiUrl)

            return fetch(apiUrl+url,{
                body: JSON.stringify(data),
                method: 'POST',
                //mode: 'cors',
                //cache: 'no-cache',
                //credentials: 'same-origin',
                credentials: 'include',

            })
        },

        Vue.prototype.$delete = function(url, data){
            // console.log(apiUrl)

            return fetch(apiUrl+url,{
                body: JSON.stringify(data),
                method: 'DELETE',
                //mode: 'cors',
                //cache: 'no-cache',
                //credentials: 'same-origin',
                credentials: 'include',

            })
        }

      }
 }
